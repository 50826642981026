
  .content {
    text-align: left;
    padding: 20px;
    margin-top: 100px;
  }
  
  blockquote {
    margin: 20px 0;
    padding: 15px 30px 15px 60px;
    position: relative;
    background-color: #f9f9f9;
    border-left: 4px solid #007bff;
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  blockquote::before {
    content: '“';
    font-size: 3em;
    position: absolute;
    left: 15px;
    top: 10px;
    color: #007bff;
    font-weight: bold;
  }
  
  blockquote::after {
    content: '';
  }
  
  blockquote cite {
    display: block;
    text-align: right;
    color: #777;
    font-style: italic;
  }
  
  blockquote cite:before {
    content: '— ';
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin-bottom: 20px;
  }
  
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .product-option {
      flex-basis: calc(50% - 40px); /* Two product options in a row */
    }
  }
  
  @media (max-width: 480px) {
    .product-option {
      flex-basis: calc(100% - 60px); /* Each product option takes the full width on smaller screens */
    }
  }