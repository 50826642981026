body {
    font-family: Arial, sans-serif; /* Use a suitable font-family for a professional look */
    margin: 0;
    padding: 0;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 12fr;  /* Two columns of equal width */
    grid-template-rows: auto auto;  /* Two rows that adapt to their content */
  }
  
  .App-logo {
    grid-row: 1 / span 2;  /* Spanning from row 1 to row 2 */
    grid-column: 1;   /* Located at column 1 */
  }
  
  .App-title {
    grid-row: 1;      /* Located at row 1 */
    grid-column: 2;   /* Located at column 2 */
    text-align: left;
  }
  
  .App-description {
    grid-row: 2;      /* Located at row 2 */
    grid-column: 2;   /* Located at column 2 */
    text-align: left; 
    font-size: 14px;
  }

  .App {
    text-align: center;
  }
  
  .App-name-color-1 {
    color: #007bff;
  }
  
  .App-name-color-3 {
    color: #808080;
  }
  
  .App-name-color-2 {
    color: #C0C0C0;
  }
  
  .App-header {
    background-color: #f0f0f0;
    padding: 5px;
    color: #333;
    text-align: center;
  
    /* Add this to set header to the top and center-align its content */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
  }
  