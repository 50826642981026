.center-block {
  display: flex;
  justify-content: center;
}

.basket-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 40px;
  place-items: center;
}

.basket-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.basket-container a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-option {
  flex: 1 0 300px;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-option h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.product-option ul {
  margin: 0;
  padding-left: 20px;
}

.product-option li {
  list-style-type: disc;
  margin-bottom: 5px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .product-option {
    flex-basis: calc(50% - 40px); /* Two product options in a row */
  }
}

@media (max-width: 480px) {
  .product-option {
    flex-basis: calc(100% - 60px); /* Each product option takes the full width on smaller screens */
  }
}