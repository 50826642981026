
.simple-text-input {
  width: 300px;
  font-size: 16px;
  padding: 10px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
}

.loader {
  opacity: 0.5;
  width: 200px;
}
.simple-text-input:focus {
  border-color: #007bff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.steps-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #FAFAFA;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .steps-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
  }

  .steps-container button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  .question-container {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .slider {
    width: 100%;
  }

  .slider-container {
    margin-bottom: 20px;
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .label {
    position: relative;
  }
  
  .label::before {
    content: attr(data-label);
    position: absolute;
    top: -20px;
    left: 0;
    transform: translateX(-50%);
    font-size: 12px;
    color: #333;
  }
  
  .label::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 10px;
    background-color: #333;
  }

  .summary-container {
    text-align: left;
  }

  .disclaimer-container {
    text-align: left;
    font-size: 14px;
    color: #666666;
  }

  .chancess-of-success-value {
    font-size: 64px;
    font-weight: bold;
  }

  .feedback-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .feedback-button {
    
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .feedback-buttons button {
    background-color: #fff!important;
    color: #bbb!important;
  }
  
  .feedback-buttons button.selected {
    color: #007bff!important;
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .feedback-comment-box {
    width: 80%;
    margin: 10px auto;
  }

  /* Media queries for responsive design */
@media (max-width: 768px) {

  }
  
  @media (max-width: 480px) {

  }
  