.emoji {
  font-size: 1.5em;
  margin-right: 10px;
}

.chat-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 40px;
}

.message {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
}

.user1 {
  background-color: #007bff;
  color: #fff;
}

.user2 {
  background-color: #eaeaea;
  color: #000;
  text-align: right;
}

.message.user1 {
  background-color: #007bff;
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 80%;
}

.message.user2 {
  background-color: #eaeaea;
  color: #000;
  text-align: right;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 20%;
}

.product-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.product-option {
  flex: 1 0 300px;
  /*width: 30%;*/
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-option button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}

.product-option h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.product-option ul {
  margin: 0;
  padding-left: 20px;
}

.product-option li {
  list-style-type: disc;
  margin-bottom: 5px;
}

.content {
  text-align: left;
  padding: 20px;
}

blockquote {
  margin: 20px 0;
  padding: 15px 30px 15px 60px;
  position: relative;
  background-color: #f9f9f9;
  border-left: 4px solid #007bff;
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

blockquote::before {
  content: '“';
  font-size: 3em;
  position: absolute;
  left: 15px;
  top: 10px;
  color: #007bff;
  font-weight: bold;
}

blockquote::after {
  content: '';
}

blockquote cite {
  display: block;
  text-align: right;
  color: #777;
  font-style: italic;
}

blockquote cite:before {
  content: '— ';
}


/* Media queries for responsive design */
@media (max-width: 768px) {
  .product-option {
    flex-basis: calc(50% - 40px); /* Two product options in a row */
  }
}

@media (max-width: 480px) {
  .product-option {
    flex-basis: calc(100% - 60px); /* Each product option takes the full width on smaller screens */
  }
}